/* eslint-disable new-cap */
import jsPDF from 'jspdf';

export const gerarCupomCaixa = (
  receipt: string,
  action: 'open' | 'send',
  cupom_name?: string,
): void | FormData => {
  const lineSpacing = 4;
  const margin = 20; // mm
  const pageWidth = 79;
  const lines = receipt.split('\n');

  const pageHeight = lines.length * lineSpacing + margin;

  const doc = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: [pageWidth, pageHeight],
  });

  doc.setFont('Courier');
  doc.setFontSize(8);
  doc.setTextColor(0, 0, 0);

  lines.forEach((line, idx) => {
    doc.text(line, 0, 10 + idx * lineSpacing);
  });

  const pdfBlob = doc.output('blob');

  if (action === 'open') {
    const pdfURL = URL.createObjectURL(pdfBlob);
    window.open(pdfURL, '_blank');
  } else if (action === 'send' && cupom_name) {
    const formData = new FormData();
    formData.append('file', pdfBlob, `${cupom_name}.pdf`);
    return formData;
  }
};

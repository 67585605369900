/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Control, FieldValues, UseFormRegister } from 'react-hook-form';
import { InputSelect } from '~/components/NovosInputs';
import api from '~/services/api';
import { SelectType } from '../../types';

interface InputFinalizadoraProps {
  name: string;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  changeSelected: (selected: SelectType) => void;
  codLoja: string | number;
  paramsApi?: Record<string, any>;
  label?: string;
  placeholder?: string;
  isError?: boolean;
  disabled?: boolean;
  iniciaRequest?: boolean;
}

/**
 * @component
 *
 * @param {Record<string, any>} paramsApi - (Opcional) Parâmetros a serem passados para o endpoint
 * @param {boolean} [iniciaRequest=true] - Default(true): Indica quando a requisição deve ser chamada
 * @returns {React.Element}
 */

export const InputFinalizadora: React.FC<InputFinalizadoraProps> = ({
  name,
  register,
  control,
  changeSelected,
  label = 'Finalizadora',
  placeholder = 'Selecione...',
  isError = false,
  disabled = false,
  iniciaRequest = true,
  paramsApi = undefined,
  codLoja,
}) => {
  const [options, setOptions] = useState<SelectType[]>([]);

  useEffect(() => {
    if (!iniciaRequest) return;

    Promise.resolve(api.get(`/finalizadoras/${codLoja}`, { params: paramsApi }))
      .then((res: any) => {
        const { data } = res;

        if (data.success) {
          const finalizadoras: any[] = data.data.map((f: any) => ({
            value: f.cod_finalizadora,
            label: f.des_finalizadora,
            tipo_finalizadora: f.tipo_finalizadora,
          }));

          setOptions(finalizadoras);
        } else setOptions([]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [codLoja, iniciaRequest, paramsApi]);

  return (
    <InputSelect
      label={label}
      placeholder={placeholder}
      name={name}
      register={register}
      isError={isError}
      control={control}
      options={options}
      changeSelected={changeSelected}
      disabled={disabled}
    />
  );
};

import { isAfter, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaFilter } from 'react-icons/fa';
import { RxReset } from 'react-icons/rx';
import { toast } from 'react-toastify';
import {
  BuscaParceiro,
  InputDate,
  InputNumber,
} from '~/components/NovosInputs';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';
import { BuscaVendaParam } from '~/pages/PdvOnline/types/services';
import { Modal } from '..';
import { TableVendas } from './components/TableVendas';
import {
  BuscaVendaContainer,
  ButtonFiltrar,
  ButtonOk,
  ButtonReset,
  HeaderContent,
} from './styles';
import { invalidField } from './utils/invalidField';
import { isValidDate } from './utils/isValidDate';
import { buscarElemento } from '~/pages/PdvOnline/utils/buscarElemento';
import { Spinner } from 'react-bootstrap';

export const ModalBuscarVendas: React.FC = () => {
  const [codPessoa, setCodPessoa] = useState<number>();

  const buttonFilterRef = useRef<HTMLButtonElement | null>(null);
  const buttonResetRef = useRef<HTMLButtonElement | null>(null);
  const headerModalRef = useRef<HTMLDivElement | null>(null);

  const {
    isModalOpen,
    handleChangeOpenModalFunction,
    onCloseModal,
    onConsultaVendas,
    onBuscarVendas,
    filterParamConsultaVenda,
    handleFilterParamConsultaVenda,
    loadingNFCe,
  } = usePdvOnline();

  const modalOpen = isModalOpen.isOpen && isModalOpen.modal === 'buscarVendas';

  const {
    register,
    control,
    resetField,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();

  const watchCliente = watch('busca_parceiro');
  const watchCupom = watch('num_pedido');
  const watchPeriodoIni = watch('dta_periodo_inicio');
  const watchPeriodoFim = watch('dta_periodo_fim');

  const disabledButtonFilter =
    invalidField(watchCliente) &&
    invalidField(watchCupom) &&
    invalidField(watchPeriodoIni) &&
    invalidField(watchPeriodoFim);

  const handleResetFilters = useCallback(() => {
    handleFilterParamConsultaVenda(undefined);
    setCodPessoa(undefined);
    resetField('busca_parceiro');
    resetField('num_pedido');
    resetField('dta_periodo_inicio');
    resetField('dta_periodo_fim');
  }, [handleFilterParamConsultaVenda, resetField]);

  const handleCloseModal = useCallback(() => {
    onCloseModal();
    handleChangeOpenModalFunction(true);
    onConsultaVendas([]);
    handleResetFilters();
  }, [
    handleChangeOpenModalFunction,
    handleResetFilters,
    onCloseModal,
    onConsultaVendas,
  ]);

  useEffect(() => {
    const handleCloseModalKeyboard = (ev: KeyboardEvent) => {
      if (modalOpen && ev.key === 'Escape') handleCloseModal();
    };

    window.addEventListener('keydown', handleCloseModalKeyboard);
    return () =>
      window.removeEventListener('keydown', handleCloseModalKeyboard);
  }, [handleCloseModal, modalOpen]);

  useEffect(() => {
    const handleFilterKey = (ev: KeyboardEvent) => {
      if (
        modalOpen &&
        (ev.key === 'F' || ev.key === 'f') &&
        buttonFilterRef.current &&
        !disabledButtonFilter
      )
        buttonFilterRef.current.click();
    };

    window.addEventListener('keydown', handleFilterKey);
    return () => window.removeEventListener('keydown', handleFilterKey);
  }, [disabledButtonFilter, modalOpen]);

  useEffect(() => {
    const handleFilterKey = (ev: KeyboardEvent) => {
      if (
        modalOpen &&
        (ev.key === 'L' || ev.key === 'l') &&
        buttonResetRef.current
      )
        buttonResetRef.current.click();
    };

    window.addEventListener('keydown', handleFilterKey);
    return () => window.removeEventListener('keydown', handleFilterKey);
  }, [disabledButtonFilter, modalOpen]);

  useEffect(() => {
    const fetchElemento = async () => {
      await onBuscarVendas(modalOpen, filterParamConsultaVenda);
    };
    fetchElemento();
  }, [filterParamConsultaVenda, modalOpen, onBuscarVendas]);

  useEffect(() => {
    const fetchElemento = async () => {
      if (modalOpen) {
        await buscarElemento(headerModalRef, 'busca_parceiro', true);

        if (headerModalRef.current) {
          const input: HTMLInputElement | null =
            headerModalRef.current.querySelector('[name="busca_parceiro"]');

          if (input) {
            input.value = '';
            input.placeholder = 'Informe o cliente...';
          }
        }
      }
    };
    fetchElemento();
  }, [modalOpen]);

  const handleFilter = useCallback(() => {
    const dtaInicio = getValues('dta_periodo_inicio') as string;
    const dtaFim = getValues('dta_periodo_fim') as string;

    const dataInicio = parseISO(dtaInicio);
    const dataFim = parseISO(dtaFim);

    if (isAfter(dataInicio, dataFim))
      return toast.warning('A data de início é posterior à data de término');

    if (!isValidDate(dataInicio) && isValidDate(dataFim)) {
      setError('dta_periodo_inicio', { type: 'required' });
      return;
    }

    if (!isValidDate(dataFim) && isValidDate(dataInicio)) {
      setError('dta_periodo_fim', { type: 'required' });
      return;
    }

    clearErrors('dta_periodo_inicio');
    clearErrors('dta_periodo_fim');

    const filters: BuscaVendaParam = {
      cod_pessoa: codPessoa,
      num_pedido: getValues('num_pedido')
        ? Number(getValues('num_pedido'))
        : undefined,
      dta_periodo_inicio: isValidDate(dataInicio) ? dataInicio : undefined,
      dta_periodo_fim: isValidDate(dataFim) ? dataFim : undefined,
    };

    handleFilterParamConsultaVenda(filters);
  }, [
    clearErrors,
    codPessoa,
    getValues,
    handleFilterParamConsultaVenda,
    setError,
  ]);

  return (
    <Modal
      isOpen={modalOpen}
      title="Consultar Vendas"
      onClose={handleCloseModal}
    >
      <BuscaVendaContainer>
        <HeaderContent ref={headerModalRef}>
          <div className="parceiro">
            <BuscaParceiro
              label="Cliente"
              placeholder="Informe o cliente..."
              name="busca_parceiro"
              register={register}
              isError={!!errors.busca_parceiro}
              control={control}
              disabled={isSubmitting}
              customOptions={{ buscarPor: { cliente: true } }}
              changeSelected={(selected: any) => {
                const { value, label, cod_pessoa } = selected;
                setValue('busca_parceiro', { value, label });
                setCodPessoa(cod_pessoa);
              }}
            />
          </div>
          <div className="inputsContainer">
            <InputNumber
              maxLength={6}
              max={999999}
              placeholder="0"
              name="num_pedido"
              label="Cupom"
              register={register}
              control={control}
              isError={!!errors.num_pedido}
              autoComplete="off"
              disabled={isSubmitting}
              onInput={(ev: any) => {
                setValue('num_pedido', ev.target.value);
              }}
              style={{ width: '15.9375rem' }}
            />

            <div className="periodoContent">
              <InputDate
                label="Período"
                name="dta_periodo_inicio"
                register={register}
                isError={!!errors.dta_periodo_inicio}
                control={control}
              />
              <InputDate
                name="dta_periodo_fim"
                register={register}
                isError={!!errors.dta_periodo_fim}
                control={control}
              />
            </div>

            <div className="buttonContainer">
              <ButtonFiltrar
                disabled={disabledButtonFilter}
                onClick={handleFilter}
                type="button"
                ref={buttonFilterRef}
              >
                <FaFilter size={20} />
                Filtrar (F)
              </ButtonFiltrar>
              <ButtonReset
                onClick={handleResetFilters}
                type="button"
                ref={buttonResetRef}
              >
                <RxReset size={20} />
                Limpar (L)
              </ButtonReset>
            </div>
          </div>
        </HeaderContent>
        <TableVendas />
        <div className="buttonOk">
          <div className="status-nfce">
            {(loadingNFCe.transmitindo || loadingNFCe.imprimindo) && (
              <Spinner
                style={{
                  color: '#297BE4',
                  marginRight: '0.3125rem',
                  marginBottom: '0.2188rem',
                }}
                animation="border"
                size="sm"
              />
            )}
            {loadingNFCe.transmitindo && <p>Transmitindo NFC-e!</p>}
            {loadingNFCe.imprimindo && <p>Imprimindo NFC-e!</p>}
          </div>
          <ButtonOk onClick={handleCloseModal} type="button">
            Ok (ESC)
          </ButtonOk>
        </div>
      </BuscaVendaContainer>
    </Modal>
  );
};

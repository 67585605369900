import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  background-color: transparent;
`;

export const TableHeaderCell = styled.th`
  padding: 0.3125rem;
  text-align: left;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

export const TableCell = styled.td`
  padding: 0.625rem;
  border: 0.0625rem solid #ddd;
  background-color: #f5f5f5;
  user-select: none;
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.625rem;
`;

export const Button = styled.button`
  margin-top: 1.875rem;
  border: none;
  padding: 0.625rem;
  width: 8.125rem;

  border-radius: 7px;

  background-color: #009e10;
  color: white;
  font-size: medium;

  &:hover {
    background-color: #00880e;
  }
`;

import styled from 'styled-components';

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.625rem;
  margin-right: 0.9375rem;
`;

export const ButtonAction = styled.button`
  border: none;
  background-color: transparent;

  &:disabled {
    cursor: not-allowed;
  }
`;
